import { Analytics } from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';

import { providerGoogleTagManager } from './providerGoogleTagManager';

const originalGoogleTagManager = googleTagManager({
  containerId: process.env.GATSBY_GTM_TRACKING_ID || ``,
});

const gtmOverrides = {
  page: providerGoogleTagManager().page,
  track: providerGoogleTagManager().track,
};

const customGoogleTagManager = {
  ...originalGoogleTagManager,
  ...gtmOverrides,
};

export const analytics = Analytics({
  app: `Hedda Health`,
  plugins: [customGoogleTagManager],
});
