import { TrackingEvent } from '@/config';

import { AnalyticsPlugin, AnalyticsPluginConfig } from '@/types';

import { isBrowser } from '@/utils';

interface GoogleTagManagerConfig extends AnalyticsPluginConfig {
  debug: boolean;
  containerId: string | null;
  dataLayerName: string;
  dataLayer: any[] | undefined;
  preview: undefined;
  auth: undefined;
  execution: string;
  assumesPageview: boolean;
}

export const defaultConfig = {
  debug: false,
  containerId: null,
  dataLayerName: `dataLayer`,
  dataLayer: undefined,
  preview: undefined,
  auth: undefined,
  execution: `async`,
  assumesPageview: true,
};

const GoogleTagManagerTrackingEvent: { [K in TrackingEvent]?: string } = {
  [TrackingEvent.ROUNDUP]: `roundup_`,
};

export const providerGoogleTagManager = (): AnalyticsPlugin<
  typeof GoogleTagManagerTrackingEvent,
  GoogleTagManagerConfig
> => {
  return {
    name: `google-tag-manager`,
    config: {
      ...defaultConfig,
    },
    initialize: ({ config }) => {
      if (isBrowser) {
        const { dataLayerName } = config;
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        config.dataLayer = window[dataLayerName];
      }
    },
    page: ({ config, payload }) => {
      const { dataLayerName } = config;
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      config.dataLayer = window[dataLayerName];
      if (typeof config.dataLayer !== `undefined`) {
        config.dataLayer.push(payload.properties);
      }
    },
    track: async ({ config, payload }) => {
      const { dataLayerName } = config;
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      config.dataLayer = window[dataLayerName];
      if (typeof config.dataLayer !== `undefined`) {
        const eventName = payload.event;
        const dataLayer = {
          event: eventName,
        };

        config.dataLayer.push(dataLayer);
      }
    },
    loaded: () =>
      isBrowser && Array.isArray(window[defaultConfig.dataLayerName as any]),
  };
};
