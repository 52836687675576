import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

import { Icon as icons, Icons } from '@/config';

export interface Props extends Omit<FontAwesomeIconProps, `icon`> {
  icon: Icons;
}

export function Icon(props: Props): JSX.Element {
  const { icon } = props;
  return <FontAwesomeIcon {...props} icon={icons[icon] || `sensor-alert`} />;
}
