import { css } from '@emotion/react';

// Biennale
import BiennaleMedium from '../fonts/biennale-medium.otf';
import BiennaleSemiBold from '../fonts/biennale-semibold.otf';
import BiennaleBold from '../fonts/biennale-bold.otf';

export const fonts = css`
  @font-face {
    font-family: Biennale;
    font-weight: 500;
    src: url(${BiennaleMedium});
    font-display: swap;
  }

  @font-face {
    font-family: Biennale;
    font-weight: 600;
    src: url(${BiennaleSemiBold});
    font-display: swap;
  }

  @font-face {
    font-family: Biennale;
    font-weight: 700;
    src: url(${BiennaleBold});
    font-display: swap;
  }
`;
