import { ReactNode, useState } from 'react';
import { useBreakpointValue } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import {
  Box,
  BoxProps,
  Conditional,
  Flex,
  Icon,
  Link,
  LinksProps,
  Text,
} from '@/components/atoms';

export interface Props extends LinksProps {
  children: ReactNode;
  href: string;
  withIcon?: boolean;
  withText?: boolean;
  boxProps?: BoxProps;
}

const AnimatedFlex = styled(Flex)`
  @keyframes anim-link {
    0% {
      transform: translateX(0px);
    }
    25% {
      transform: translateX(110%);
      opacity: 1;
    }
    30.1% {
      transform: translateX(110%);
      opacity: 0;
    }
    30.2% {
      transform: translateX(-100%);
    }
    30.3% {
      transform: translateX(-100%);
      opacity: 1;
    }
    60% {
      transform: translateX(0px);
    }
  }
`;

export function GradientLink(props: Props): JSX.Element {
  const {
    boxProps,
    children,
    href,
    withIcon = false,
    withText = true,
    ...rest
  } = props;

  const [animate, setAnimate] = useState(false);
  const iconSize = useBreakpointValue({ base: 15, sm: 8 });

  return (
    <Box
      width="max-content"
      overflow="hidden"
      onMouseEnter={() => setAnimate(true)}
      onMouseLeave={() => setAnimate(false)}
      {...boxProps}
    >
      {/*@ts-ignore*/}
      <Box
        as={(props) => (
          <Link href={href} style={{ textDecoration: `none` }} {...props} />
        )}
        {...rest}
      >
        <AnimatedFlex
          alignItems="center"
          width="max-content"
          overflow="hidden"
          css={
            animate
              ? css`
                  animation: 1s cubic-bezier(0.25, 1, 0.3, 1) anim-link both;
                `
              : css`
                  animation: none;
                `
          }
          sx={{
            '& .gradient-text': {
              background: `linear-gradient(90deg, #A770EF 0%, #CF8BF3 5%, #FDB99B 100%)`,
              WebkitBackgroundClip: `text`,
              WebkitTextFillColor: `transparent`,
            },
            svg: {
              path: {
                fill: `url(#paint0_linear_3333_9364)`,
              },
            },
          }}
        >
          <svg width="0" height="0">
            <defs>
              <radialGradient
                id="paint0_linear_3333_9364"
                r="150%"
                cx="30%"
                cy="107%"
              >
                <stop stopColor="#A770EF" />
                <stop offset="0.5" stopColor="#CF8BF3" />
                <stop offset="1" stopColor="#FDB99B" />
              </radialGradient>
            </defs>
          </svg>
          <Conditional isTrue={withText}>
            <Text
              variant="1"
              mr={withIcon ? 2 : 0}
              textAlign="center"
              className="gradient-text"
            >
              {children}
            </Text>
          </Conditional>
          <Conditional isTrue={withIcon}>
            <Box mt={1}>
              <Icon icon="chevron-right" width={iconSize} />
            </Box>
          </Conditional>
        </AnimatedFlex>
      </Box>
    </Box>
  );
}
