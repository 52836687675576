import { ElementType, forwardRef } from 'react';
// eslint-disable-next-line import/no-named-default
import { Link as GatsbyLink } from 'gatsby';
import {
  Link as ChakraLink,
  LinkProps,
  useStyleConfig,
} from '@chakra-ui/react';
import { onlyText } from 'react-children-utilities';

export type LinkVariant = `default` | `nav` | `footer` | `legal` | `common`;

export interface Props extends LinkProps {
  variant?: LinkVariant;
}

export const Link = forwardRef<HTMLAnchorElement, Props>(
  (props, ref): JSX.Element => {
    const { children, ...rest } = props;
    const style = useStyleConfig(`Link`, props);

    return (
      <ChakraLink
        ref={ref}
        sx={style}
        title={onlyText(children)}
        {...rest}
        as={((props) => <GatsbyLink {...props} />) as ElementType}
      >
        {children}
      </ChakraLink>
    );
  }
);
