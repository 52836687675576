import { faClock } from '@fortawesome/pro-light-svg-icons';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import {
  faApple,
  faFacebook,
  faGooglePlay,
  faInstagram,
  faLinkedin,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';

export type Icons =
  | IconName
  | `${IconName}_light`
  | `${IconName}_regular`
  | `${IconName}_solid`;

export const Icon: { [K in Icons]?: IconDefinition } = {
  apple: faApple,
  'chevron-right': faChevronRight,
  clock: faClock,
  facebook: faFacebook,
  'google-play': faGooglePlay,
  instagram: faInstagram,
  linkedin: faLinkedin,
  tiktok: faTiktok,
};
