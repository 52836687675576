import type { GatsbyBrowser } from 'gatsby';
import { AnalyticsProvider } from 'use-analytics';

import { Fonts } from '@/components/atoms';

import { analytics } from '@/tracking';

export const wrapPageElement: GatsbyBrowser[`wrapPageElement`] = ({
  element,
}) => {
  return (
    <AnalyticsProvider instance={analytics}>
      <Fonts />
      {element}
    </AnalyticsProvider>
  );
};
